import latinize from 'latinize';
/**
 * Latinizes string by removing language specific characters.
 *
 * @param string
 * @returns {string}
 */
function latinizeString(string) {
  // Characters behaviour could be modified by extending the characters object, like this:
  latinize.characters['ü'] = 'ue';
  latinize.characters['ß'] = 'ss';
  latinize.characters['ä'] = 'ae';
  latinize.characters['ö'] = 'oe';

  // Start Greek letters
  latinize.characters['Α'] = 'A';
  latinize.characters['Ά'] = 'A';
  latinize.characters['Β'] = 'V';
  latinize.characters['Γ'] = 'G';
  latinize.characters['Δ'] = 'D';
  latinize.characters['Ε'] = 'E';
  latinize.characters['Έ'] = 'E';
  latinize.characters['Ζ'] = 'Z';
  latinize.characters['Η'] = 'I';
  latinize.characters['Ή'] = 'I';
  latinize.characters['Θ'] = 'Th';
  latinize.characters['Ι'] = 'I';
  latinize.characters['Ί'] = 'I';
  latinize.characters['Κ'] = 'K';
  latinize.characters['Λ'] = 'L';
  latinize.characters['Μ'] = 'M';
  latinize.characters['Ν'] = 'N';
  latinize.characters['Ξ'] = 'KS';
  latinize.characters['Ο'] = 'O';
  latinize.characters['Ό'] = 'O';
  latinize.characters['Π'] = 'P';
  latinize.characters['Ρ'] = 'R';
  latinize.characters['Σ'] = 'S';
  latinize.characters['Τ'] = 'T';
  latinize.characters['Υ'] = 'Y';
  latinize.characters['Ύ'] = 'Y';
  latinize.characters['Φ'] = 'F';
  latinize.characters['Χ'] = 'X';
  latinize.characters['Ψ'] = 'Ps';
  latinize.characters['Ω'] = 'Ο';
  latinize.characters['Ώ'] = 'Ο';
  latinize.characters['α'] = 'a';
  latinize.characters['ά'] = 'a';
  latinize.characters['β'] = 'v';
  latinize.characters['γ'] = 'g';
  latinize.characters['δ'] = 'd';
  latinize.characters['ε'] = 'e';
  latinize.characters['έ'] = 'e';
  latinize.characters['ζ'] = 'z';
  latinize.characters['η'] = 'i';
  latinize.characters['ή'] = 'i';
  latinize.characters['θ'] = 'th';
  latinize.characters['ι'] = 'i';
  latinize.characters['ί'] = 'i';
  latinize.characters['ϊ'] = 'i';
  latinize.characters['ΐ'] = 'i';
  latinize.characters['κ'] = 'k';
  latinize.characters['λ'] = 'l';
  latinize.characters['μ'] = 'm';
  latinize.characters['ν'] = 'n';
  latinize.characters['ξ'] = 'ks';
  latinize.characters['ο'] = 'o';
  latinize.characters['ό'] = 'o';
  latinize.characters['π'] = 'p';
  latinize.characters['ρ'] = 'r';
  latinize.characters['σ'] = 's';
  latinize.characters['ς'] = 's';
  latinize.characters['τ'] = 't';
  latinize.characters['υ'] = 'y';
  latinize.characters['ύ'] = 'y';
  latinize.characters['ΰ'] = 'y';
  latinize.characters['ϋ'] = 'y';
  latinize.characters['φ'] = 'f';
  latinize.characters['χ'] = 'x';
  latinize.characters['ψ'] = 'ps';
  latinize.characters['ω'] = 'o';
  latinize.characters['ώ'] = 'o';
  // End Greek letters

  // Override ö => oe to ö => o only for Hungary and Turkey.
  if (process.env.REACT_APP_COUNTRY === 'tr' || process.env.REACT_APP_COUNTRY === 'hu') {
    latinize.characters['ü'] = 'u';
    latinize.characters['ö'] = 'o';
  }

  return latinize(string);
}

export default latinizeString;
